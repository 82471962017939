<template>
  <svg
    width="341"
    height="100"
    viewBox="0 0 341 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_648_2711)">
      <path
        d="M119.976 11.7988V32.8561C122.701 30.728 126.108 29.4683 129.513 29.4683C136.033 29.4683 141.775 34.1132 141.775 43.7916V75.8236C141.775 85.4994 136.033 90.1469 129.513 90.1469C125.523 90.1469 121.631 88.5012 118.709 85.695L117.251 89.3723H111.312V16.329L119.973 11.7988H119.976ZM119.976 41.374V78.2438C122.116 80.4685 124.356 81.6317 127.081 81.6317C131.265 81.6317 133.116 78.8254 133.116 73.9875V45.6329C133.116 40.795 131.267 37.9887 127.081 37.9887C124.356 37.9887 122.116 39.1493 119.976 41.3766V41.374Z"
        fill="#C33191"
      />
      <path
        d="M156.663 70.7899V75.5313C156.663 79.5947 159.291 81.8219 162.892 81.8219C166.493 81.8219 169.218 79.5973 169.218 75.5313V73.2363L177.103 69.0765V75.8208C177.103 84.7247 170.679 90.1442 162.601 90.1442C154.522 90.1442 148.293 84.7247 148.293 75.8208V43.7889C148.293 34.8851 154.619 29.4656 162.698 29.4656C169.706 29.4656 177.297 33.2394 177.297 45.046C177.297 52.3042 174.377 57.4316 167.76 62.8511C165.035 65.1748 161.337 67.8846 156.665 70.7873L156.663 70.7899ZM156.663 62.4677C159.485 60.629 161.822 58.8869 163.377 57.4342C167.367 53.7568 168.924 50.369 168.924 45.6276C168.924 39.53 166.005 37.4018 162.598 37.4018C158.9 37.4018 156.66 39.9186 156.66 44.1749V62.4651L156.663 62.4677Z"
        fill="#C33191"
      />
      <path
        d="M190.243 30.2429L191.604 33.6308C194.62 30.921 198.515 29.4709 202.311 29.4709C209.122 29.4709 214.767 34.1159 214.767 43.7943V89.375H206.106V45.633C206.106 40.795 204.257 37.9888 199.877 37.9888C197.346 37.9888 195.108 39.0529 192.965 41.0845V89.375H184.305V30.2429H190.243Z"
        fill="#C33191"
      />
      <path
        d="M288.055 11.7988V89.3722H282.116L280.655 85.6949C277.639 88.5011 273.747 90.1468 269.852 90.1468C263.331 90.1468 257.59 85.5019 257.59 75.8235V43.7916C257.59 34.1131 263.331 29.4682 269.852 29.4682C273.259 29.4682 276.566 30.7253 279.389 32.8561V16.3941L288.049 11.7961L288.055 11.7988ZM266.253 73.9848C266.253 78.8227 268.005 81.629 272.288 81.629C274.917 81.629 277.254 80.4684 279.394 78.2411V41.3713C277.254 39.1466 274.917 37.9835 272.288 37.9835C268.005 37.9835 266.253 40.7897 266.253 45.6276V73.9822V73.9848Z"
        fill="#C33191"
      />
      <path
        d="M305.378 16.5976C305.378 19.5968 303.723 22.0171 300.219 22.0171C296.909 22.0171 295.254 19.5968 295.254 16.5976C295.254 13.5984 296.909 11.3711 300.219 11.3711C303.723 11.3711 305.378 13.6922 305.378 16.5976ZM304.696 30.2429V89.3697H296.036V30.2429H304.696Z"
        fill="#C33191"
      />
      <path
        d="M320.561 75.3384C320.561 79.4018 323.189 81.629 326.79 81.629C330.392 81.629 333.117 79.4044 333.117 75.3384V73.2155L341.001 69.0791V75.8235C341.001 84.7274 334.578 90.1469 326.499 90.1469C318.421 90.1469 312.191 84.7274 312.191 75.8235V43.7916C312.191 34.8878 318.518 29.4683 326.499 29.4683C334.481 29.4683 341.001 34.8878 341.001 43.7916V46.3084H333.117V44.2767C333.117 40.2134 330.489 37.9861 326.79 37.9861C323.092 37.9861 320.561 40.2108 320.561 44.2767V75.341V75.3384Z"
        fill="#C33191"
      />
      <path
        d="M230.436 70.7899V75.5313C230.436 79.5947 233.064 81.8219 236.665 81.8219C240.267 81.8219 242.992 79.5973 242.992 75.5313V73.2363L250.876 69.0765V75.8208C250.876 84.7247 244.453 90.1442 236.374 90.1442C228.296 90.1442 222.066 84.7247 222.066 75.8208V43.7889C222.066 34.8851 228.393 29.4656 236.471 29.4656C243.48 29.4656 251.07 33.2394 251.07 45.046C251.07 52.3042 248.151 57.4316 241.533 62.8511C238.808 65.1748 235.11 67.8846 230.439 70.7873L230.436 70.7899ZM230.436 62.4677C233.258 60.629 235.595 58.8869 237.151 57.4342C241.14 53.7568 242.698 50.369 242.698 45.6276C242.698 39.53 239.779 37.4018 236.372 37.4018C232.673 37.4018 230.433 39.9186 230.433 44.1749V62.4651L230.436 62.4677Z"
        fill="#C33191"
      />
      <path
        d="M60.153 46.0815C56.3341 50.5648 50.5533 54.6672 41.3995 59.4973L0 81.3474V100H43.7784V99.9949C43.8414 99.9949 43.9017 100 43.9646 100C60.2789 100 73.5035 86.8504 73.5035 70.6284C73.5035 60.3553 68.1869 51.3315 60.153 46.0815Z"
        fill="#C33191"
      />
      <path
        d="M59.0776 29.3717C59.0776 13.1497 45.8531 0 29.5388 0C13.2245 0 0 13.1497 0 29.3717V74.7646L38.6586 54.3646C48.5075 49.1667 59.0776 43.6768 59.0776 29.3743V29.3717Z"
        fill="#C33191"
      />
    </g>
    <defs>
      <clipPath id="clip0_648_2711">
        <rect
          width="341"
          height="100"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
